<template>
  <div></div>
</template>
<script>
  export default {
    data() {
      return {}
    },
    created() {
      this.goBackMini()
    },
    methods: {
      goBackMini() {
        window.wx.miniProgram.navigateBack()
      },
    },
  }
</script>
<style lang="less" scoped></style>
